import { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ClickOutside } from '../common';
import IconSVG from '../../styles/svg-icons';
import { EmptyPlaceholder } from '../common';
import { Tranche } from '../../types/amr-pipeline/models/Tranche';
import { Table } from '../bidding/common/table';
import { constants } from '../../constants';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import moment from 'moment';
import { startCase } from 'lodash';

interface Props {
    classes: Tranche[];
    selectedClassReferenceName?: string;
    onChange: (key: string) => void;
    dropdownClass?: string;
}

const classSelectColumnDefinitions: IColumnDefinition<Tranche>[] = [
    {
        columnKey: 'className',
        renderColumnHeaderContent: () => 'Class',
        renderColumnContent: dealClass => dealClass.name,
    },
    {
        columnKey: 'closingDate',
        renderColumnHeaderContent: () => 'Closing',
        renderColumnContent: dealClass =>
            dealClass.transactionClosingDate
                ? moment(dealClass.transactionClosingDate).format(constants.dateFormat)
                : constants.emptyPlaceholder,
        className: "closing-type-cell"
    },
    {
        columnKey: 'type',
        renderColumnHeaderContent: () => 'Type',
        renderColumnContent: dealClass => startCase(dealClass.transactionType),
        className: "closing-type-cell"
    },
];

export const classSelectColumns = classSelectColumnDefinitions.map(c => {
    return new ColumnBuilder(c);
});

export function ClassSelect({ classes, selectedClassReferenceName, onChange, dropdownClass }: Props) {
    const selected = classes.find(c => c.referenceName === selectedClassReferenceName);
    const [expanded, setExpanded] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        ref.current?.scrollIntoView();
    },[expanded]);

    const onRowClick = (dealClass: Tranche) => {
        onChange(dealClass.referenceName);
        setExpanded(false);
    };

    return (
        <ClickOutside className={classNames('component-dropdown', dropdownClass)} onClick={() => setExpanded(false)}>
            <div
                className={classNames('component-dropdown-toggle', {
                    'component-dropdown-toggle-selected': expanded,
                })}
                onClick={() => setExpanded(!expanded)}
            >
                <span>Class {selected?.name}</span>
                <IconSVG
                    name="corner-arrow"
                    width="16"
                    height="16"
                    className={classNames('icon-component-dropdown', {
                        'is-open': expanded,
                    })}
                />
            </div>
            {expanded && (
                <div className="component-dropdown-list">
                    {classes.length ? (
                        <Table
                            className="data-list-striped data-list-share-transaction"
                            dataItems={classes}
                            columns={classSelectColumns}
                            createRowRef={(item: Tranche) => (item.referenceName === selectedClassReferenceName ? ref : null)}
                            onRowClick={onRowClick}
                            createSecurityCustomClassName={(c: Tranche) => {
                                let classes = [];

                                if (c.referenceName === selectedClassReferenceName) {
                                    classes.push('selected');
                                }

                                if (c.status === "Inactive") {
                                    classes.push('inactive-class');
                                }

                                return classes.join(' ');
                            }}
                        />
                    ) : (
                        <EmptyPlaceholder textView text="No items found" />
                    )}
                </div>
            )}
        </ClickOutside>
    );
}
