import { amrPipelineDetailedActions } from "../../../../actions";
import { AmrDocument } from "../../../../types/amr-pipeline/models/AmrDocument";
import { Table } from "../../../bidding/common/table";
import { useAppDispatch } from "../../../../effects/useAppDispatch";
import { EmptySectionContent } from "../../../amrPipeline/common/section/EmptySectionContent";
import { SORT, SORTING_TYPE, constants, roles } from "../../../../constants";
import { user } from "../../../../user";
import { IColumnDefinition } from "../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import IconSVG from "../../../../styles/svg-icons";
import { Checkbox, FileIcon } from "../../../controls";
import { dealRegularDocTypeLabels } from "../../../../types/amr-pipeline/enums/DocumentType";
import moment from "moment";
import DownloadDocument from "../../../amrPipeline/common/documents/DownloadDocument";
import { OnHoverTooltip } from '../../../common';

export type DocumentColumn = IColumnDefinition<AmrDocument>;

interface Props {
    dealReferenceName: string;
    items: AmrDocument[];
    withType?: boolean;
    withPublic?: boolean;
    onClick?: (document: AmrDocument) => void;
}

export function DocumentsList({
    dealReferenceName,
    items,
    withType = false,
    withPublic = false,
    onClick
}: Props) {
    const dispatch = useAppDispatch();
    const isAdminOrDataEntry = user.hasRoles(
        roles.Administrator,
        roles.DataEntry
    );

    function onRowClick(document: AmrDocument) {
        if (onClick) {
            onClick(document);
            return;
        }

        dispatch(
            amrPipelineDetailedActions.downloadDealDocument(
                dealReferenceName,
                document.referenceName,
                document.name
            )
        );
    }

    const getColumns = () => {
        const documentsColumnDefinitions: DocumentColumn[] = [
            {
                columnKey: "name",
                renderColumnHeaderContent: () => "Name",
                headerClassName: "data-list-cell-xl-flexible",
                bodyClassName: "data-list-cell-xl-flexible",
                renderColumnContent: (document) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => onRowClick(document)}
                    >
                        <FileIcon filename={document.name} />
                        {document.name}
                    </DownloadDocument>
                ),
                sortingField: "name",
                sortingType: SORTING_TYPE.string,
            },
            ...(withType
                ? [
                    {
                        columnKey: "documentType",
                        renderColumnHeaderContent: () => "Type",
                        headerClassName: "data-list-cell-lg-02",
                        bodyClassName: "data-list-cell-lg-02",
                        renderColumnContent: ({ documentType }) => {
                            const typeLabel =
                                dealRegularDocTypeLabels[
                                    documentType as keyof typeof dealRegularDocTypeLabels
                                ];
                            return (
                                <OnHoverTooltip overlay={typeLabel}>
                                    {typeLabel}
                                </OnHoverTooltip>
                            );
                        },
                        sortingField: "documentType",
                        sortingType: SORTING_TYPE.string,
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: "executionDate",
                renderColumnHeaderContent: () => "Date",
                headerClassName: "data-list-cell-lg",
                bodyClassName: "data-list-cell-lg",
                renderColumnContent: (document) =>
                    moment
                        .utc(document.executionDate)
                        .format(constants.dateFormat),
                sortingField: "executionDate",
                sortingType: SORTING_TYPE.date,
            },
            ...(isAdminOrDataEntry && withPublic
                ? [
                    {
                        columnKey: "isPublic",
                        renderColumnHeaderContent: () => "Public",
                        headerClassName: "data-list-cell-xs",
                        bodyClassName: "data-list-cell-xs",
                        renderColumnContent: (document) => (
                            <Checkbox disabled checked={document.isPublic} />
                        ),
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: "downloadButton",
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xs",
                bodyClassName: "data-list-cell-xs",
                renderColumnContent: (document) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => onRowClick(document)}
                    >
                        <IconSVG
                            name="downloadTemplate"
                            width={16}
                            height={16}
                        />
                    </DownloadDocument>
                ),
            },
        ];

        return documentsColumnDefinitions.map((c) => {
            return new ColumnBuilder(c);
        });
    };

    return (
        <div className="data-item-documents">
            {items.length ? (
                <Table
                    className="component-file-upload-list data-list-striped"
                    dataItems={items}
                    onRowClick={onRowClick}
                    columns={getColumns()}
                    defaultSortBy="executionDate"
                    defaultSortByDirection={SORT.DESC}
                />
            ) : (
                <EmptySectionContent text="There are no documents yet" />
            )}
        </div>
    );
}
