import { FileUploader } from "../../../../amrPipeline/common/documents/FileUploader";
import { EditDocumentsList } from "./EditDocumentsList";
import { DocumentGroup } from "../../../../amrPipeline/common/documents/DocumentGroup";
import { AmrFile } from "../../../../../types/amr-pipeline/models/AmrFile";

const maxDealDocSize = 50;

interface Props {
    title: string;
    dealReferenceName: string;
    items: AmrFile[];
    documentKey: string;
    withTypeColumn?: boolean;
    withPublicColumn?: boolean;
    disabledDate?: boolean;
    onDownloadAll: () => void;
    onUpload: (documents: AmrFile[]) => void;
    setUploadProgress: (isUploading: boolean) => void;
}

export const EditDocumentsSection = ({
    title,
    dealReferenceName,
    items,
    documentKey,
    withTypeColumn,
    withPublicColumn,
    disabledDate = false,
    onDownloadAll,
    onUpload,
    setUploadProgress,
}: Props) => {
    return (
        <DocumentGroup
            title={title}
            itemsCount={items.filter((i) => !i.remove).length}
            downloadAll={onDownloadAll}
        >
            <FileUploader
                referenceName={dealReferenceName}
                documents={items}
                maxFileSizeInMb={maxDealDocSize}
                onChange={onUpload}
                onUploading={setUploadProgress}
            >
                {(props) => (
                    <EditDocumentsList
                        {...props}
                        documentKey={documentKey}
                        disabledDate={disabledDate}
                        withTypeColumn={withTypeColumn}
                        withPublicColumn={withPublicColumn}
                    />
                )}
            </FileUploader>
        </DocumentGroup>
    );
};

