import { FormikProps, Formik } from 'formik';
import { useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dealsActions } from '../../../../../actions/deals.actions';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { DealSave } from '../../../../../types/deals/DealSave';
import { AppState } from '../../../../../types/state/AppState';
import { DealsState } from '../../../../../types/state/DealsState';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { BanksValidationSchema } from '../../../../banks/edit/BanksValidationSchema';
import { RouteLeavingGuard } from '../../../../routing';
import { collateralTypesSelectOptions } from '../../../../../constants/collateral-types';
import { Currencies } from '../../../../../types/enums/Currency';
import { FormField } from '../../../../amrPipeline/common/FormField';
import { constants, routes } from '../../../../../constants';
import { formatUtils } from '../../../../../utils';
import { DealStatus } from '../../../../../types/amr-pipeline/enums/DealStatus';
import moment from 'moment';
import { Preloader } from '../../../../common';
import { times } from 'lodash';
import { DealsSaveHeader } from '../../../DealsSaveHeader';
import { DealsTabType } from '../../../../../types/deals/DealsTabType';
import { Company } from "../../../../../types/amr-pipeline/models/Company";
import { useParties } from '../../../../../effects/data-accessors/useParties';
import { TextField } from '../../../../common/form-fields/TextField';
import { SelectField } from '../../../../common/form-fields/SelectField';
import { NumberField } from '../../../../common/form-fields/NumberField';
import { CheckboxField } from '../../../../common/form-fields/CheckboxField';
import { DateField } from '../../../../common/form-fields/DateField';
import { MarkdownField } from '../../../../common/form-fields/MarkdownField';
import { SelectSearchField } from '../../../../forms/SelectSearchField';

const currencyOptions = Currencies.map(currency => ({ value: currency, label: currency }));
const statusOptions = [
    {
        value: DealStatus.Ready,
        label: 'Ready',
    },
    {
        value: DealStatus.NewIssueDraft,
        label: 'New Issue',
    },
    {
        value: DealStatus.Draft,
        label: 'Draft',
    },
];

const quarterlyDaysOptions = times(31, index => ({
    value: index + 1,
    label: index + 1,
}));

const quarterlyMonthsOptions = ['Jan, Apr, Jul, Oct', 'Feb, May, Aug, Nov', 'Mar, Jun, Sep, Dec'].map(months => ({
    value: months,
    label: months,
}));

const formatCompaniesToSelect = (selectedReferenceName?: string) => (company: Company) => ({
    value: company.referenceName,
    text: company.legalName,
    selected: company.referenceName === selectedReferenceName,
});

export const EditDealDetails = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const formRef = useRef<FormikProps<any>>(null);
    const { editDeal, isUpdating } = useSelector<AppState, DealsState>(state => state.deals);
    const { items: companies } = useParties();

    const availableStatusOptions = useMemo(() => {
        const showDraft = !editDeal?.transactions?.length && !editDeal?.hasAmrTransactions;

        return showDraft ? statusOptions : statusOptions.filter(option => option.value !== DealStatus.Draft);
    }, [editDeal?.hasAmrTransactions, editDeal?.transactions?.length]);

    useEffect(() => {
        dispatch(dealsActions.editDeal());

        return () => {
            dispatch(dealsActions.editDealReset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!editDeal || !companies) {
        return null;
    }

    if (isUpdating) {
        return <Preloader inProgress />
    }

    const handleSubmit = (values: DealSave) => {
        const deals = { ...values, transactions: undefined };

        dispatch(dealsActions.updateDeal(editDeal.referenceName, deals));
    };

    const calculateFactor = (currentBalance?: number, originalBalance?: number) => {
        if (!currentBalance || !originalBalance) {
            return constants.emptyPlaceholder;
        }

        return formatUtils.formatFactor(currentBalance / originalBalance);
    };

    return (
        <Formik
            initialValues={editDeal}
            innerRef={formRef}
            validationSchema={BanksValidationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, dirty, isSubmitting, submitForm }) => (
                <>
                    <DealsSaveHeader
                        title="Edit Deal Detail"
                        subTitle={editDeal.legalName}
                        hasChanges={dirty}
                        onSaveClick={submitForm}
                        onCancelClick={() =>
                            history.push(routes.dealsUrl(editDeal.referenceName, DealsTabType.DealDetail))
                        }
                    />
                    <RouteLeavingGuard
                        navigate={(pathname: string) => history.push(pathname)}
                        shouldBlockNavigation={() => !!dirty && !isSubmitting}
                    />
                    <div className="tabs-data tabs-data-padding tabs-data-deal-detail-edit">
                        <div className="data-item-row-wrap">
                            <div className="data-item-row-col">
                                <SectionContent title="Information">
                                    <TextField
                                        label="Deal Name"
                                        name="legalName"
                                        required
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <TextField label="Ticker" name="ticker" wrapperClassName="data-item-row-title-md" />
                                    <SelectField
                                        label="Collateral Type"
                                        name="collateralType"
                                        values={collateralTypesSelectOptions}
                                        placeholder="Select Collateral Type"
                                        optionKeyProp="value"
                                        optionLabelProp="label"
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <SelectField
                                        label="Currency"
                                        name="currencyCode"
                                        values={currencyOptions}
                                        placeholder="Select Currency"
                                        optionKeyProp="value"
                                        optionLabelProp="label"
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <NumberField
                                        label="Orig. Balance"
                                        placeholder="Orig. Balance"
                                        name="originalBalance"
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <NumberField
                                        label="Cur. Balance"
                                        placeholder="Cur. Balance"
                                        name="currentBalance"
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <FormField
                                        title="Factor"
                                        value={calculateFactor(values.currentBalance, values.originalBalance)}
                                        className="data-item-row-title-md"
                                    />
                                    <SelectField
                                        label="Status"
                                        name="status"
                                        values={availableStatusOptions}
                                        placeholder="Status"
                                        optionKeyProp="value"
                                        optionLabelProp="label"
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <CheckboxField
                                        label="Redeemed"
                                        name="redeemed"
                                        wrapperClassName="data-item-row-checkbox"
                                    />
                                </SectionContent>
                            </div>
                            <div className="data-item-row-col">
                                <SectionContent title="Dates">
                                    <DateField
                                        dateFormat={constants.dateFormat}
                                        label="Closing Date"
                                        name="closingDate"
                                        emptyIfNull
                                        wrapperClassName="data-item-row-title-md"
                                        withCustomView
                                    />
                                    <DateField
                                        dateFormat={constants.dateFormat}
                                        label="Non Call End"
                                        name="nonCallPeriodEnd"
                                        emptyIfNull
                                        wrapperClassName="data-item-row-title-md"
                                        withCustomView
                                    />
                                    <DateField
                                        dateFormat={constants.dateFormat}
                                        label="Reinvestment End"
                                        name="reinvestmentPeriodEnd"
                                        emptyIfNull
                                        wrapperClassName="data-item-row-title-md"
                                        withCustomView
                                    />
                                    <FormField
                                        title="Last Refinancing"
                                        value={formatUtils.formatDate(values.lastRefinancingDate)}
                                        className="data-item-row-title-md"
                                    />
                                    <DateField
                                        dateFormat={constants.dateFormat}
                                        label="Maturity"
                                        name="finalMaturity"
                                        emptyIfNull
                                        wrapperClassName="data-item-row-title-md"
                                        withCustomView
                                    />
                                    <FormField
                                        title="Deal Vintage"
                                        value={
                                            values.vintage ? moment(values.vintage).year() : constants.emptyPlaceholder
                                        }
                                        className="data-item-row-title-md"
                                    />
                                    <DateField
                                        dateFormat={constants.dateFormat}
                                        label="First Payment Date"
                                        name="firstPaymentDate"
                                        emptyIfNull
                                        wrapperClassName="data-item-row-title-md"
                                        withCustomView
                                    />
                                    <DateField
                                        dateFormat={constants.dateFormat}
                                        label="Second Payment Date"
                                        name="secondPaymentDate"
                                        emptyIfNull
                                        wrapperClassName="data-item-row-title-md"
                                        withCustomView
                                    />

                                    <SelectField
                                        values={quarterlyDaysOptions}
                                        optionKeyProp="value"
                                        optionLabelProp="label"
                                        label="Quarterly Day"
                                        name="quarterlyDay"
                                        withEmptyValue
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <SelectField
                                        label="Quarterly Months"
                                        name="quarterlyMonths"
                                        values={quarterlyMonthsOptions}
                                        placeholder="Quarterly Months"
                                        optionKeyProp="value"
                                        optionLabelProp="label"
                                        withEmptyValue
                                        wrapperClassName="data-item-row-title-md"
                                    />
                                    <DateField
                                        dateFormat={constants.dateFormat}
                                        label="Redemption Date"
                                        name="redemptionDate"
                                        emptyIfNull
                                        wrapperClassName="data-item-row-title-md"
                                        withCustomView
                                    />
                                </SectionContent>
                            </div>
                        </div>

                        <SectionContent title="Parties">
                            <div className="data-item-row-wrap">
                                <div className="data-item-row-col">
                                    <FormField title="Orig. Arranger" className="data-item-row-title-md">
                                        <SelectSearchField
                                            className="select-search-field"
                                            label=""
                                            name="originalArranger.referenceName"
                                            markRequired={false}
                                            placeholder="Select Orig. Arranger"
                                            withResetOption
                                            options={companies.arrangerCompanies.map(
                                                formatCompaniesToSelect(
                                                    values.originalArranger
                                                        ?.referenceName
                                                )
                                            )}
                                        />
                                    </FormField>
                                    <FormField title="Cur. Arranger" className="data-item-row-title-md">
                                        <SelectSearchField
                                            className="select-search-field"
                                            label=""
                                            name="currentArranger.referenceName"
                                            markRequired={false}
                                            placeholder="Select Cur. Arranger"
                                            withResetOption
                                            options={companies.arrangerCompanies.map(
                                                formatCompaniesToSelect(
                                                    values.currentArranger
                                                        ?.referenceName
                                                )
                                            )}
                                        />
                                    </FormField>
                                    <FormField title="Trustee" className="data-item-row-title-md">
                                        <SelectSearchField
                                            className="select-search-field"
                                            label=""
                                            name="trustee.referenceName"
                                            markRequired={false}
                                            placeholder="Select Trustee"
                                            withResetOption
                                            options={companies.trusteeCompanies.map(
                                                formatCompaniesToSelect(
                                                    values.trustee
                                                        ?.referenceName
                                                )
                                            )}
                                        />
                                    </FormField>
                                </div>
                                <div className="data-item-row-col">
                                    <FormField title="Collateral Manager" className="data-item-row-title-md">
                                        <SelectSearchField
                                            className="select-search-field"
                                            label=""
                                            name="collateralManager.referenceName"
                                            markRequired={false}
                                            placeholder="Select Collateral Manager"
                                            withResetOption
                                            options={companies.collateralManagerCompanies.map(
                                                formatCompaniesToSelect(
                                                    values.collateralManager
                                                        ?.referenceName
                                                )
                                            )}
                                        />
                                    </FormField>
                                    <FormField title="Majority Equity" className="data-item-row-title-md">
                                        <SelectSearchField
                                            className="select-search-field"
                                            label=""
                                            name="majorityEquity.referenceName"
                                            markRequired={false}
                                            placeholder="Select Majority Equity"
                                            withResetOption
                                            options={companies.majorityEquityCompanies.map(
                                                formatCompaniesToSelect(
                                                    values.majorityEquity
                                                        ?.referenceName
                                                )
                                            )}
                                        />
                                    </FormField>
                                    <FormField title="AMR Agent" className="data-item-row-title-md">
                                        <SelectSearchField
                                            className="select-search-field"
                                            label=""
                                            name="amrAgent.referenceName"
                                            markRequired={false}
                                            placeholder="Select AMR Agent"
                                            withResetOption
                                            options={companies.amrAgentCompanies.map(
                                                formatCompaniesToSelect(
                                                    values.amrAgent
                                                        ?.referenceName
                                                )
                                            )}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </SectionContent>
                        <SectionContent title="Additional Information">
                            <CheckboxField
                                label="EU Compliance"
                                name="euCompliance"
                                wrapperClassName="data-item-row-checkbox"
                            />
                            <MarkdownField
                                label="EU Risk Retention Req."
                                name="euRiskRetentionRequirements"
                                maxLength={2048}
                                wrapperClassName="data-item-row-full-height data-item-row-title-md"
                            />
                            <MarkdownField
                                label="EU Disclosure Req."
                                name="euDisclosureRequirements"
                                maxLength={2048}
                                wrapperClassName="data-item-row-full-height data-item-row-title-md"
                            />
                            <CheckboxField label="ESG" name="esg" wrapperClassName="data-item-row-checkbox" />
                            <MarkdownField
                                label="ESG Comment"
                                name="esgComment"
                                maxLength={2048}
                                wrapperClassName="data-item-row-full-height data-item-row-title-md"
                            />
                            <div className="data-item-row-wrap">
                                <div className="data-item-row-col">
                                    <CheckboxField
                                        label="Static Deal"
                                        name="staticDeal"
                                        wrapperClassName="data-item-row-checkbox"
                                    />
                                </div>
                                <div className="data-item-row-col">
                                    <CheckboxField
                                        label="Deal with AMR"
                                        name="amrDeal"
                                        wrapperClassName="data-item-row-checkbox"
                                    />
                                </div>
                            </div>
                        </SectionContent>
                        <div className="data-item-row-wrap">
                            <div className="data-item-row-col">
                                <SectionContent title="Management Fee">
                                    <NumberField
                                        label="Senior Management Fee, bps"
                                        name="seniorManagementFee"
                                        placeholder="Senior Manag. Fee, bps"
                                        capitalize={false}
                                    />
                                    <NumberField
                                        label="Sub Management Fee, bps"
                                        name="subManagementFee"
                                        placeholder="Sub Management Fee, bps"
                                        capitalize={false}
                                    />
                                    <NumberField
                                        label="Incentive Management Fee, bps"
                                        name="incentiveManagementFee"
                                        placeholder="Incentive Manag. Fee, %"
                                        capitalize={false}
                                    />
                                </SectionContent>
                            </div>
                            <div className="data-item-row-col">
                                <SectionContent title="Administrative Fee">
                                    <NumberField
                                        label="Admin Expense Cap, bps"
                                        name="adminExpenseCapPercentage"
                                        placeholder="Admin Expense Cap, bps"
                                        capitalize={false}
                                    />
                                    <NumberField
                                        label="Admin Expense Cap, $"
                                        name="adminExpenseCap"
                                        placeholder="Admin Expense Cap, $"
                                        capitalize={false}
                                    />
                                    <NumberField
                                        label="Trustee, bps"
                                        name="trusteeFee"
                                        placeholder="Trustee, bps"
                                        capitalize={false}
                                    />
                                </SectionContent>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};
