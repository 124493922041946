import { useSelector } from "react-redux";
import { AppState } from "../../../../types/state/AppState";
import { DealsState } from "../../../../types/state/DealsState";
import {
    DocumentType,
    getDealRegularDocumentTypes,
} from "../../../../types/amr-pipeline/enums/DocumentType";
import { AmrDocument } from "../../../../types/amr-pipeline/models/AmrDocument";
import { DocumentsList } from "./DocumentsList";
import { amrPipelineDetailedActions } from "../../../../actions/amr-pipeline-detailed.actions";
import { useAppDispatch } from "../../../../effects/useAppDispatch";
import { DealsDefaultHeader } from "../../DealsDefaultHeader";
import { roles, routes } from "../../../../constants";
import { DealsTabType } from "../../../../types/deals/DealsTabType";
import { DocumentGroup } from "../../../amrPipeline/common/documents/DocumentGroup";
import { compareDates } from "../../../../utils/compare.utils";
import { user } from "../../../../user";

export const groupDocuments = (documentList: AmrDocument[] = []) => {
    const sortedDocumentList = documentList
        .map((d) => ({ ...d, executionDate: d.executionDate ?? d.uploadTime }))
        .sort((a, b) => compareDates(b.executionDate, a.executionDate));

    return {
        dealDocuments: sortedDocumentList.filter((d) =>
            getDealRegularDocumentTypes().includes(d.documentType)
        ),
        distributionReports: sortedDocumentList.filter(
            (d) => d.documentType === DocumentType.distributionReport
        ),
        monthlyReports: sortedDocumentList.filter(
            (d) => d.documentType === DocumentType.monthlyReport
        ),
        reportsExcelFiles: sortedDocumentList.filter(
            (d) => d.documentType === DocumentType.reportsExcelFiles
        ),
        otherDocuments: sortedDocumentList.filter(
            (d) => d.documentType === DocumentType.otherDocument
        ),
    };
};

export const Documents = () => {
    const { selectedDeal } = useSelector<AppState, DealsState>(
        (state) => state.deals
    );
    const dispatch = useAppDispatch();
    const isAdminOrDataEntry = user.hasRoles(roles.Administrator, roles.DataEntry);

    if (!selectedDeal) {
        return null;
    }

    const { referenceName, ticker } = selectedDeal;

    const allDocuments = isAdminOrDataEntry ? selectedDeal.documents : selectedDeal.documents?.filter(d => d.isPublic);

    const {
        dealDocuments,
        distributionReports,
        monthlyReports,
        reportsExcelFiles,
        otherDocuments,
    } = groupDocuments(allDocuments);

    return (
        <div className="tabs-content container-flex">
            <DealsDefaultHeader
                isEditDisabled={!ticker}
                editTooltip={ticker ? undefined : "Please add the deal ticker before editing documents"}
                editButtonUrl={
                    isAdminOrDataEntry
                        ? routes.editDealUrl(
                              selectedDeal.referenceName,
                              DealsTabType.Documents
                          )
                        : undefined
                }
            />
            <div className="tabs-data tabs-data-padding tabs-data-documents">
                <DocumentGroup
                    title="Deal Documents"
                    itemsCount={dealDocuments.length}
                    downloadAll={() =>
                        dispatch(
                            amrPipelineDetailedActions.downloadDealRegularDocuments(
                                referenceName,
                                ticker
                            )
                        )
                    }
                >
                    <DocumentsList
                        dealReferenceName={referenceName}
                        items={dealDocuments}
                        withType
                    />
                </DocumentGroup>
                <DocumentGroup
                    title="Distribution Reports"
                    itemsCount={distributionReports.length}
                    downloadAll={() =>
                        dispatch(
                            amrPipelineDetailedActions.downloadDealDocuments(
                                referenceName,
                                ticker,
                                DocumentType.distributionReport
                            )
                        )
                    }
                >
                    <DocumentsList
                        dealReferenceName={referenceName}
                        items={distributionReports}
                    />
                </DocumentGroup>
                <DocumentGroup
                    title="Monthly Reports"
                    itemsCount={monthlyReports.length}
                    downloadAll={() =>
                        dispatch(
                            amrPipelineDetailedActions.downloadDealDocuments(
                                referenceName,
                                ticker,
                                DocumentType.monthlyReport
                            )
                        )
                    }
                >
                    <DocumentsList
                        dealReferenceName={referenceName}
                        items={monthlyReports}
                    />
                </DocumentGroup>
                {isAdminOrDataEntry && (
                    <DocumentGroup
                        title="Reports Excel files"
                        itemsCount={reportsExcelFiles.length}
                        downloadAll={() =>
                            dispatch(
                                amrPipelineDetailedActions.downloadDealDocuments(
                                    referenceName,
                                    ticker,
                                    DocumentType.reportsExcelFiles
                                )
                            )
                        }
                    >
                        <DocumentsList
                            dealReferenceName={referenceName}
                            items={reportsExcelFiles}
                        />
                    </DocumentGroup>
                )}
                <DocumentGroup
                    title="Other Documents"
                    itemsCount={otherDocuments.length}
                    downloadAll={() =>
                        dispatch(
                            amrPipelineDetailedActions.downloadDealDocuments(
                                referenceName,
                                ticker,
                                DocumentType.otherDocument
                            )
                        )
                    }
                >
                    <DocumentsList
                        dealReferenceName={referenceName}
                        items={otherDocuments}
                    />
                </DocumentGroup>
            </div>
        </div>
    );
};

